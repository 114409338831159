:root{
  --line-cl:#dcdcdc;
  --light-500:#a3a3a3;
}

@media screen and (max-width:580px){
  :root{
    --heading-fz:35px
  }
}


.wrapper {
  width: 80%;
  margin: 0 auto;
  max-width: 1920px;
}
@font-face {
  font-family: "gellix-light";
  src: url(../fonts/Gellix-Light.woff) format(woff);
}
@font-face {
  font-family: "gellix-thin";
  src: url(../fonts/Gellix-Thin.woff) format(woff);
}

@font-face {
  font-family: "gellix-regular";
  src: url(../fonts/Gellix-Regular.woff) format(woff);
}
@font-face {
  font-family: "gellix-bold";
  src: url(../fonts/Gellix-Bold.woff) format(woff);
}
@font-face {
  font-family: "gellix-medium";
  src: url(../fonts/Gellix-Medium.woff) format(woff);
}

body {
  scroll-behavior: smooth;
  font-family: "gellix-regular";
}
.fix-image{
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  display: block;
  opacity: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -2;
}
a {
  text-decoration: none;
}
img {
  display: block;
  width: 100%;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

:root {
  --orange: #ea6503;
  --black: #2a2b29;
}
#background-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  /* position: fixed; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.drop{
  -webkit-appearance: none !important;
  -moz-appearance: none;
}
.testimonial-slider .slick-dots{
  bottom: -53px !important;
}
.testimonial-slider .slick-prev:before {
  content: url("../images/apartments/gallery/newDesign/icons/leftArr.svg") !important;
  width: 60px !important;
}

/* ----react slick slider in testimonials---------- */
.testimonial-slider .slick-next:before {
  content: url("../images/apartments/gallery/newDesign/icons/rightarr.svg") !important;
  width: 60px !important;
 
}
.testimonial-slider .slick-prev:before {
  content: url("../images/apartments/gallery/newDesign/icons/leftArr.svg") !important;
  width: 40px !important;
}

.testimonial-slider .slick-prev,
.testimonial-slider .slick-next {
}
.testimonial-slider .slick-prev {
  left: -78px !important;
}
.testimonial-slider .slick-next {
 

}

.mobile-slider .slick-dots li button::before{
    color: transparent;
    border: 1px solid var(--light-500);
    opacity: 1;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
.mobile-slider .slick-dots li.slick-active button::before{
  background-color: var(--light-500);
  color: transparent;
  width: 7px;
  height: 7px;
}
.player{
  height: 100% !important;
  object-fit: cover;
}
.tooltip-description{
 width: 750px !important;
 background-color: #d3d3d3 !important;
 color: #000 !important;
}
  .event-video{
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
@media(max-width:1580px){
  .tooltip-description{
    width: 600px !important;
  }
  .event-video{
    width: 100%;
    height: 100%;
  }
}
@media(max-width:580px){
  /* .testimonial-slider .slick-next:before {
    content: url("../images/icons/mob-left-arrow.svg") !important;
    width: 30px !important;
    display: block;
  }
  .testimonial-slider .slick-prev:before {
    content: url("../images/icons/mob-right-arrow.svg") !important;
    width: 30px !important;
    display: block;
  }

  .testimonial-slider .slick-prev {
    left: -40px !important;
  }
  .testimonial-slider .slick-next {
    right: -40px !important;
    display: flex !important;
    justify-content: end;
  } */
  .tooltip-description{
    width: 80% !important;
  }
}

@media (max-width:480px){
  .wrapper{
    width: 90%;
  }
}
